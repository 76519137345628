import './App.scss';
import Landing from './components/landing-component/landing';

function App() {
  return (
    <Landing />
  );
}

export default App;

import styles from './titantron.module.scss';
import gsap  from 'gsap';
import { useEffect } from 'react';
import logo from '../../assets/logo1.png';

export default function Titantron() {

  useEffect(() => {
    gsap.fromTo('#titantronTitle', { opacity: 0, y: -25 }, { opacity: 1, y: 0, ease: 'circ' });
    gsap.fromTo('#titantronSubtitle', { opacity: 0, y: 25 }, { opacity: 1, y: 0, ease: 'circ' });
  }, []);

  return (
    <div className={styles.titantron}>
      <div className={styles.logoNameContainer}>
        <img src={logo} className={styles.logo} alt='logo' />
        <h2 className={styles.titantronTitle} id="titantronTitle">WrapIndustries</h2>
      </div>
      <div className={styles.titantronSubtitleContainer}>
        <div className={styles.contactContainer}>
          <p className={styles.contactInfo}>rob@wrapindustries.ca</p>
          <p className={styles.contactInfo}>{'Text (905) 392-7082'}</p>
        </div>
        <p className={styles.titantronSubtitle} id="titantronSubtitle">
          Striping, graphics and lettering since 1984. Paint Protection Film (PPF) since 1995. We only use the
          highest quality films available for long lasting durable results to keep you and your vehicle happy for
          years to come!
        </p>
      </div>
    </div>
  );
}

import porsche from '../../assets/porsche.jpeg';
import p1 from '../../assets/graphicswrap/1.jpeg'
import p2 from '../../assets/graphicswrap/2.jpeg';
import p3 from '../../assets/graphicswrap/3.jpeg';
import p4 from '../../assets/graphicswrap/4.jpeg';
import p5 from '../../assets/graphicswrap/5.jpeg';
import p6 from '../../assets/graphicswrap/6.jpeg';
import p7 from '../../assets/graphicswrap/7.jpeg';
import p8 from '../../assets/graphicswrap/8.jpeg';
import p9 from '../../assets/graphicswrap/9.jpeg';
import p10 from '../../assets/graphicswrap/10.jpeg';
import p11 from '../../assets/graphicswrap/11.jpeg';
import p12 from '../../assets/graphicswrap/12.jpeg';
import p13 from '../../assets/graphicswrap/13.jpg';
import p14 from '../../assets/graphicswrap/14.jpg';
import p15 from '../../assets/graphicswrap/15.jpg';
import p16 from '../../assets/graphicswrap/16.jpg';
import p17 from '../../assets/graphicswrap/17.jpg';
import p18 from '../../assets/graphicswrap/18.jpg';
import p19 from '../../assets/graphicswrap/19.jpg';
import p20 from '../../assets/graphicswrap/20.jpg';
import p21 from '../../assets/graphicswrap/21.jpg';
import p22 from '../../assets/graphicswrap/22.jpg';
import p23 from '../../assets/graphicswrap/23.jpeg';
import p24 from '../../assets/graphicswrap/24.jpeg';
import p25 from '../../assets/graphicswrap/25.jpg';
import p26 from '../../assets/graphicswrap/26.jpg';
import p27 from '../../assets/graphicswrap/27.jpg';
import p28 from '../../assets/graphicswrap/28.jpg';
import p29 from '../../assets/graphicswrap/29.jpg';
import p30 from '../../assets/graphicswrap/30.jpg';
import p31 from '../../assets/graphicswrap/31.jpg';
import p32 from '../../assets/graphicswrap/32.jpg';
import p33 from '../../assets/graphicswrap/33.jpg';
import p34 from '../../assets/graphicswrap/34.jpeg';
import p35 from '../../assets/graphicswrap/35.jpeg';
import p36 from '../../assets/graphicswrap/36.jpeg';
import p37 from '../../assets/graphicswrap/37.jpeg';
import p38 from '../../assets/graphicswrap/38.jpeg';
import p39 from '../../assets/graphicswrap/39.jpeg';
import p40 from '../../assets/graphicswrap/40.jpeg';

export const COMPONENTS_LIST = [
    {
        title: "",
        subtitle: "Custom striping and graphics designs, race car graphics, partial and full vehicle wraps",
        img: [
            {
                img: porsche,
                name: ""
            },
            {
                img: p1,
                name: ""
            },
            {
                img: p2,
                name: ""
            },
            {
                img: p3,
                name: ""
            },
            {
                img: p4,
                name: ""
            },
            {
                img: p5,
                name: ""
            },
            {
                img: p6,
                name: ""
            },
            {
                img: p7,
                name: ""
            },
            {
                img: p8,
                name: ""
            },
            {
                img: p9,
                name: ""
            },
            {
                img: p10,
                name: ""
            },
            {
                img: p11,
                name: ""
            },
            {
                img: p12,
                name: ""
            },
            {
                img: p13,
                name: ""
            },
            {
                img: p14,
                name: ""
            },
            {
                img: p15,
                name: ""
            },
            {
                img: p16,
                name: ""
            },
            {
                img: p17,
                name: ""
            },
            {
                img: p18,
                name: ""
            },
            {
                img: p19,
                name: ""
            },
            {
                img: p20,
                name: ""
            },
            {
                img: p21,
                name: ""
            },
            {
                img: p22,
                name: ""
            },
            {
                img: p23,
                name: ""
            },
            {
                img: p24,
                name: ""
            },
            {
                img: p25,
                name: ""
            },
            {
                img: p26,
                name: ""
            },
            {
                img: p27,
                name: ""
            },
            {
                img: p28,
                name: ""
            },
            {
                img: p29,
                name: ""
            },
            {
                img: p30,
                name: ""
            },
            {
                img: p31,
                name: ""
            },
            {
                img: p32,
                name: ""
            },
            {
                img: p33,
                name: ""
            },
            {
                img: p34,
                name: ""
            },
            {
                img: p35,
                name: ""
            },
            {
                img: p36,
                name: ""
            },
            {
                img: p37,
                name: ""
            },
            {
                img: p38,
                name: ""
            },
            {
                img: p39,
                name: ""
            },
            {
                img: p40,
                name: ""
            }
        ]
    }
]
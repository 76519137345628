import { COMPONENTS_LIST } from './constants';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './headlight-restoration.module.scss';
import CardComponentCarousel from '../card-component-carousel/card-component-carousel';

export default function HeadlightRestoration() {
    return (
        <div className={styles.sectionWrapper}>
            <div className={styles.sectionHeader}>
                Headlight Restoration
            </div>
            <Container className={styles.landingItemContainer}>
                <Row>
                    {COMPONENTS_LIST.map((item, idx) => {
                        return <Col xl="12" key={idx}>
                            <CardComponentCarousel
                                title={item.title}
                                subtitle={item.subtitle}
                                img={item.img}
                            />
                        </Col>
                    })}
                </Row>
                <br /><br /><br />
            </Container>
        </div>
    );
}

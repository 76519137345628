import headlight1 from '../../assets/BeforeAfterHeadlight1.png';
import h1 from '../../assets/headlight/1.jpg';
import h2 from '../../assets/headlight/2.jpeg';
import h3 from '../../assets/headlight/3.jpeg';
import h4 from '../../assets/headlight/4.jpeg';
import h5 from '../../assets/headlight/5.jpeg';
import h6 from '../../assets/headlight/6.jpeg';
import h7 from '../../assets/headlight/7.jpeg';

export const COMPONENTS_LIST = [
    {
        title: "",
        subtitle: "See clearly at night again! We restore your foggy yellowed headlights to like new condition, then we apply clear protection film to keep you seeing clearly for years to come.",
        img: [
            {
                name: "",
                img: headlight1
            },
            {
                name: "",
                img: h1
            },
            {
                name: "",
                img: h2
            },
            {
                name: "",
                img: h3
            },
            {
                name: "",
                img: h4
            },
            {
                name: "",
                img: h5
            },
            {
                name: "",
                img: h6
            },
            {
                name: "",
                img: h7
            }
        ]
    }
]
import Titantron from '../titantron-component/titantron';
import BlackPPF from '../black-ppf/black-ppf';
import GraphicsWraps from '../graphics-wraps/graphics-wraps';
import Lettering from '../lettering/lettering';
import HeadlightRestoration from '../headlight-restoration/headlight-restoration';

export default function Landing() {
    return (
        <>
            <Titantron />
            <BlackPPF />
            <GraphicsWraps />
            <Lettering />
            <HeadlightRestoration />
        </>
    );
}

import lexus from '../../assets/lexus.png';
import bppf1 from '../../assets/black-ppf/1.jpeg';
import bppf2 from '../../assets/black-ppf/2.jpeg';
import bppf3 from '../../assets/black-ppf/3.jpeg';
import ppfRes1 from '../../assets/ppf-res/1.jpeg';
import ppfRes2 from '../../assets/ppf-res/2.jpeg';
import ppfRes3 from '../../assets/ppf-res/3.jpeg';
import ppfRes4 from '../../assets/ppf-res/4.jpeg';
import ppfRes5 from '../../assets/ppf-res/5.png';
import ppfRes6 from '../../assets/ppf-res/6.jpeg';
import ppfRes7 from '../../assets/ppf-res/7.jpeg';
import ppf1 from '../../assets/ppf/1.jpeg';
import ppf2 from '../../assets/ppf/2.jpeg';
import ppf3 from '../../assets/ppf/3.jpeg';
import ppf4 from '../../assets/ppf/4.jpg';
import ppf5 from '../../assets/ppf/5.jpg';
import ppf6 from '../../assets/ppf/6.jpg';
import ppf7 from '../../assets/ppf/7.jpg';
import ppf8 from '../../assets/ppf/8.jpg';
import ppf9 from '../../assets/ppf/9.jpg';
import ppf10 from '../../assets/ppf/10.jpg';
import ppf11 from '../../assets/ppf/11.jpg';
import ppf12 from '../../assets/ppf/12.jpg';
import ppf13 from '../../assets/ppf/13.jpg';
import ppf14 from '../../assets/ppf/14.jpg';
import ppf15 from '../../assets/ppf/15.jpg';
import ppf16 from '../../assets/ppf/16.jpg';

export const COMPONENTS_LIST = [
    {
        title: "How Much Coverage Do You Need?",
        subtitle: "Gloss or matte finish",
        img: [
            {
                img: lexus,
                name: ""
            },
            {
                img: ppf1,
                name: ""
            },
            {
                img: ppf2,
                name: ""
            },
            {
                img: ppf3,
                name: ""
            },
            {
                img: ppf4,
                name: ""
            },
            {
                img: ppf5,
                name: ""
            },
            {
                img: ppf6,
                name: ""
            },
            {
                img: ppf7,
                name: ""
            },
            {
                img: ppf8,
                name: ""
            },
            {
                img: ppf9,
                name: ""
            },
            {
                img: ppf10,
                name: ""
            },
            {
                img: ppf11,
                name: ""
            },
            {
                img: ppf12,
                name: ""
            },
            {
                img: ppf13,
                name: ""
            },
            {
                img: ppf14,
                name: ""
            },
            {
                img: ppf15,
                name: "Matte PPF"
            },
            {
                img: ppf16,
                name: "Matte PPF"
            }
        ]
    },
    {
        title: "Black Paint Protection Film (NEW)",
        subtitle: "Have a black car with chips or scratches? No need to repaint and then put on clear film - Now available black PPF. Cover all chips and blemishes, at half the cost.",
        img: [
            {
                img: bppf1,
                name: ""
            },
            {
                img: bppf2,
                name: ""
            },
            {
                img: bppf3,
                name: ""
            }
        ]
    },
    {
        title: "Paint Protection Film Restoration",
        subtitle: "Do you have a pristine older vehicle with chipped, dry-cracked old PPF detracting from the look of your otherwise beautiful car? We remove and replace old film with new film and make your car look like new again!",
        img: [
            {
                img: ppfRes1,
                name: ""
            },
            {
                img: ppfRes2,
                name: "2008 Porche Boxster"
            },
            {
                img: ppfRes3,
                name: ""
            },
            {
                img: ppfRes4,
                name: "2004 Audi RS6"
            },
            {
                img: ppfRes5,
                name: ""
            },
            {
                img: ppfRes6,
                name: ""
            },
            {
                img: ppfRes7,
                name: ""
            }
        ]
    }
]
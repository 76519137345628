import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from './card-component-carousel.module.scss';

export default function CardComponentCarousel(props) {
  return (
    <>
      <div className={styles.container}>
        {/* <img src={props.img} className={styles.digitalIcon} alt="img" /> */}
        <Carousel className={styles.carousel}>
          {props.img.map((item, idx) => {
            return <div className={styles.carouselContainer}>
            <img src={item.img} alt={item.name} className={styles.digitalIcon} />
            {item.name !== "" ? <p className='legend'>{item.name}</p> : null}
          </div>
          })}
        </Carousel>
        {props.title === "" ? null : <h2 className={styles.title}>{props.title}</h2>}
        <p className={styles.subtitle}>{props.subtitle}</p>
      </div>
    </>
  );
}

import l1 from '../../assets/lettering/1.jpeg';
import l2 from '../../assets/lettering/2.jpeg';
import l3 from '../../assets/lettering/3.jpeg';
import l4 from '../../assets/lettering/4.jpg';
import l5 from '../../assets/lettering/5.jpeg';

export const COMPONENTS_LIST = [
    {
        title: "",
        subtitle: "Vinyl cut lettering and decals for vehicles and trailers etc.",
        img: [
            {
                img: l1,
                name: ""
            },
            {
                img: l2,
                name: ""
            },
            {
                img: l3,
                name: ""
            },
            {
                img: l4,
                name: ""
            },
            {
                img: l5,
                name: ""
            }
        ]
    }
]